import { ActionReducerMapBuilder, createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { selectDrawableActiveFloor } from './documents'
import { ADD_BUILDING, DELETE_BUILDING, SET_PROJECT_BUILDINGS, UPDATE_BUILDING } from '../../actions/drawable'
import {
    BUILDING_SETTING_KEYS,
    BUILDING_SETTING_OPTION_KEYS,
    FIELD_KEY,
    otherValue,
    soffitTypeVentTypes,
} from '../../components/projects/project-settings/utils/constants'
import { valueIncludesCedarOrPine } from '../../components/projects/project-settings/utils/helpers'
import { Building } from '../../models/projectBuilding'
import { RootState } from '../../stores'

export const setBuildingsAction = createAction<{ buildings: Building[] }>(SET_PROJECT_BUILDINGS)
export const updateBuilding = createAction<{ building: Building }>(UPDATE_BUILDING)
export const deleteBuilding = createAction<{ buildingId: Building['id'] }>(DELETE_BUILDING)
export const addBuilding = createAction<{ building: Building }>(ADD_BUILDING)

type BuildingsState = {
    buildings: Building[]
    activeBuilding: Building | null
    buildingActiveTab: BUILDING_SETTING_KEYS | null
    buildingSettingOptions: any // TODO: update any
}

type UpdateKeyValueType = {
    [x: string]: string | number | boolean | null | object
}

export const initialBuildingsSate: BuildingsState = {
    buildings: [],
    activeBuilding: null,
    buildingActiveTab: null,
    buildingSettingOptions: {},
}

const handleSetBuildings = (state: BuildingsState, action: PayloadAction<{ buildings: Building[] }>) => {
    state.buildings = action.payload && action.payload.buildings ? action.payload.buildings : []
}

const handleUpdateBuilding = (state: BuildingsState, action: PayloadAction<{ building: Building }>) => {
    state.buildings = state.buildings.map((building) => {
        if (action.payload && action.payload.building?.id && building.id === action.payload.building.id) {
            return action.payload.building
        }

        return building
    })
}

const handleDeleteBuilding = (state: BuildingsState, action: PayloadAction<{ buildingId: Building['id'] }>) => {
    state.buildings = state.buildings.filter((building) => building.id !== action.payload.buildingId)
}

const handleAddBuilding = (state: BuildingsState, action: PayloadAction<{ building: Building }>) => {
    state.buildings =
        action.payload && action.payload.building ? [...state.buildings, action.payload.building] : state.buildings
}

const handleSetActiveBuilding = (state: BuildingsState, action: PayloadAction<{ building: Building }>) => {
    state.activeBuilding = state.buildings.find((building) => building.id === action.payload.building.id) || null
}

const handleSetBuildingActiveTab = (state: BuildingsState, action: PayloadAction<BUILDING_SETTING_KEYS | null>) => {
    state.buildingActiveTab = action.payload
}

const handleSetBuildingSettingOptions = (state: BuildingsState, action: PayloadAction<any>) => {
    const { tabKey, options } = action.payload

    state.buildingSettingOptions[tabKey] = options
}

const handleSetBuildingsSelectedOptions = (
    state: BuildingsState,
    action: PayloadAction<{
        key: string
        value: string | number | boolean | object | null
        isRobustOverhangV2Enabled?: boolean
    }>
) => {
    const { key, value, isRobustOverhangV2Enabled } = action.payload
    const updatedBuildings = [...state.buildings].map((building) => {
        if (building?.id === state.activeBuilding!.id) {
            if (state.buildingActiveTab === BUILDING_SETTING_KEYS.LUMBER_SETTINGS) {
                const [material, field, selection] = key.split('-')

                return {
                    ...building,
                    settings: {
                        ...building?.settings,
                        lumber_settings: {
                            ...building?.settings?.lumber_settings,
                            [material]: {
                                ...building?.settings?.lumber_settings?.[material],
                                [field]: {
                                    ...building?.settings?.lumber_settings?.[material]?.[field],
                                    [selection]: value,
                                },
                            },
                        },
                    },
                }
            }

            // waste_factors and project_materials are under framing_settings
            if (
                state.buildingActiveTab === BUILDING_SETTING_KEYS.WASTE_FACTORS ||
                state.buildingActiveTab === BUILDING_SETTING_KEYS.PROJECT_MATERIALS
            ) {
                const buildingData = {
                    ...building,
                    settings: {
                        ...building?.settings,
                        framing_settings: {
                            ...building?.settings.framing_settings,
                            [state.buildingActiveTab]: {
                                ...building?.settings.framing_settings[state.buildingActiveTab],
                                [key]: value,
                            },
                        },
                    },
                }

                // slab on grade and ground floor treated can't be selected at the same time
                // when slab on grade is selected, unset ground floor treated
                if (BUILDING_SETTING_OPTION_KEYS.SLAB_ON_GRADE === key && value) {
                    buildingData.settings.framing_settings[state.buildingActiveTab][
                        BUILDING_SETTING_OPTION_KEYS.GROUND_FLOOR_TREATED
                    ] = false
                }

                // when a ground floor treated is selected, unset slab on grade
                if (BUILDING_SETTING_OPTION_KEYS.GROUND_FLOOR_TREATED === key && value) {
                    buildingData.settings.framing_settings[state.buildingActiveTab][
                        BUILDING_SETTING_OPTION_KEYS.SLAB_ON_GRADE
                    ] = false
                }

                return buildingData
            }

            // reset rafter tail fields
            if (key === FIELD_KEY.RAFTER_TAIL && !isRobustOverhangV2Enabled) {
                // clean all when NONE is selected
                if (value === 'NONE') {
                    building.settings.exterior_settings.eave_blocking_width = null
                    building.settings.exterior_settings.rafter_tail_size = null
                    building.settings.exterior_settings.rafter_tail_length = null
                    building.settings.exterior_settings.rafter_tail_spacing = null
                }

                // clear eave_blocking_width when not EAVES and not EAVES & GABLES are selected
                if (value !== 'EAVES' && value !== 'EAVES & GABLES') {
                    building.settings.exterior_settings.eave_blocking_width = null
                }
            }

            // reset soffit vent type
            if (key === FIELD_KEY.SOFFIT_TYPE && !soffitTypeVentTypes.includes(value as string)) {
                building.settings.exterior_settings.soffit_vent_type = null
            }

            if (BUILDING_SETTING_OPTION_KEYS.SOFFIT_TYPE === key && !valueIncludesCedarOrPine(value as string)) {
                building.settings.exterior_settings.soffit_tg_width = null
            }

            let updateKeyValue: UpdateKeyValueType = {
                [key]:
                    state.buildingActiveTab === BUILDING_SETTING_KEYS.EXTERIOR_WASTE_FACTORS &&
                    typeof value === 'string'
                        ? Number(value)
                        : value,
            }

            // used to unset the field_other values to placeholder instead of previous selected value
            const otherKey = `${key}_other`

            if (
                building?.settings[state.buildingActiveTab as BUILDING_SETTING_KEYS]?.hasOwnProperty(otherKey) &&
                value === otherValue
            ) {
                updateKeyValue = {
                    [key]: value,
                    [otherKey]: null,
                }
            }

            return {
                ...building,
                settings: {
                    ...building?.settings,
                    [state.buildingActiveTab as BUILDING_SETTING_KEYS]: {
                        ...building?.settings[state.buildingActiveTab as BUILDING_SETTING_KEYS],
                        ...updateKeyValue,
                    },
                },
            }
        }

        return building
    })

    const updatedActiveBuilding = updatedBuildings.find((building) => building.id === state.activeBuilding!.id)

    if (updatedActiveBuilding) {
        state.activeBuilding = updatedActiveBuilding
    }

    state.buildings = updatedBuildings
}

const extraReducers = (builder: ActionReducerMapBuilder<BuildingsState>): void => {
    builder.addCase(setBuildingsAction, handleSetBuildings)
    builder.addCase(updateBuilding, handleUpdateBuilding)
    builder.addCase(deleteBuilding, handleDeleteBuilding)
    builder.addCase(addBuilding, handleAddBuilding)
}

const buildingsSlice = createSlice({
    name: 'buildings',
    initialState: initialBuildingsSate,
    reducers: {
        setBuildings: handleSetBuildings,
        setActiveBuilding: handleSetActiveBuilding,
        setBuildingActiveTab: handleSetBuildingActiveTab,
        setBuildingSettingOptions: handleSetBuildingSettingOptions,
        setBuildingsSelectedOptions: handleSetBuildingsSelectedOptions,
    },
    extraReducers,
})

export const {
    setBuildings,
    setActiveBuilding,
    setBuildingActiveTab,
    setBuildingSettingOptions,
    setBuildingsSelectedOptions,
} = buildingsSlice.actions

export const selectBuildings = createSelector(
    (state: RootState) => state.IMUP.buildings.buildings,
    (buildings) => buildings
)

export const selectActiveBuilding = createSelector(
    (state: RootState) => state.IMUP.buildings.activeBuilding,
    (activeBuilding) => activeBuilding
)

export const selectBuildingActiveTab = createSelector(
    (state: RootState) => state.IMUP.buildings.buildingActiveTab,
    (buildingActiveTab) => buildingActiveTab
)

export const selectBuildingSettingOptions = createSelector(
    (state: RootState) => state.IMUP.buildings.buildingSettingOptions,
    (buildingSettingOptions) => buildingSettingOptions
)

export const selectBuildingSettingFromActiveTab = createSelector(
    (state: RootState) => state.IMUP.buildings.buildingSettingOptions,
    (state: RootState) => state.IMUP.buildings.buildingActiveTab,
    (buildingSettingOptions, buildingActiveTab) => buildingSettingOptions[buildingActiveTab!]
)
export const selectActiveBuildingFromActiveFloor = createSelector(
    [(state: RootState) => selectDrawableActiveFloor(state), (state: RootState) => state.IMUP.buildings.buildings],
    (floor, buildings) => buildings.find((b: Building) => b.id === floor?.building_id)
)

export default buildingsSlice
