import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'

import { selectActiveDocumentMapping } from './documents'
import { DocumentMapping } from '../../models/documentMapping'
import { IMasterSetPlanOption, IMasterSetPlanToCreate, IMaterialModificationConflict } from '../../models/masterSetPlan'
import { RootState } from '../../stores'

export type MasterSetPlanRegionFormFields = {
    name: string | null
    type: string | null
    scale?: string
}

export type MasterSetPlanState = {
    masterSetPlanOptions: IMasterSetPlanOption[]
    masterSetPlanToCreate: IMasterSetPlanToCreate[]
    activeMasterSetPlanOptionId: number | null
    masterSetPlanFormDataState: MasterSetPlanRegionFormFields
    materialModificationConflicts: IMaterialModificationConflict[]
    materialModificationConflictsToSave: IMaterialModificationConflict[]
    savedMaterialModifications: IMaterialModificationConflict[]
    isConflictResolutionComplete: boolean
    isOverlaidChunkLocked: boolean
}

export const initialMasterSetPlanRegionFormState: MasterSetPlanRegionFormFields = {
    name: null,
    type: null,
    scale: undefined,
}

export const initialMasterSetPlanState: MasterSetPlanState = {
    activeMasterSetPlanOptionId: null,
    masterSetPlanFormDataState: initialMasterSetPlanRegionFormState,
    masterSetPlanOptions: [],
    masterSetPlanToCreate: [],
    materialModificationConflicts: [],
    materialModificationConflictsToSave: [],
    savedMaterialModifications: [],
    isConflictResolutionComplete: false,
    isOverlaidChunkLocked: false,
}

const masterSetPlanSlice = createSlice({
    name: 'masterSetPlan',
    initialState: initialMasterSetPlanState,
    reducers: {
        addMasterSetPlanOptionToStore: (
            state: MasterSetPlanState,
            action: PayloadAction<IMasterSetPlanOption | IMasterSetPlanOption[]>
        ) => {
            state.masterSetPlanOptions = isArray(action.payload)
                ? [...state.masterSetPlanOptions, ...action.payload]
                : [...state.masterSetPlanOptions, action.payload]
        },

        setMasterSetPlanToCreate: (state: MasterSetPlanState, action: PayloadAction<IMasterSetPlanToCreate[]>) => {
            state.masterSetPlanToCreate = [...action.payload]
        },

        resetMasterSetPlanFormState: (state: MasterSetPlanState) => {
            return {
                ...state,
                masterSetPlanRegionFormState: initialMasterSetPlanRegionFormState,
                activeMasterSetPlanOptionId: null,
            }
        },

        removeMasterSetPlanOptionById: (state: MasterSetPlanState, action: PayloadAction<number>) => {
            state.masterSetPlanOptions = state.masterSetPlanOptions.filter((option) => option.id !== action.payload)
        },

        updateMasterSetPlanOptions: (
            state: MasterSetPlanState,
            action: PayloadAction<IMasterSetPlanOption | IMasterSetPlanOption[]>
        ) => {
            state.masterSetPlanOptions = isArray(action.payload) ? action.payload : [action.payload]
        },

        setActiveMasterSetPlanOption: (state: MasterSetPlanState, action: PayloadAction<number | null>) => {
            state.activeMasterSetPlanOptionId = action.payload

            const masterSetPlanOption = state.masterSetPlanOptions.find((o) => o.id === action.payload)

            if (masterSetPlanOption) {
                state.masterSetPlanFormDataState.name = masterSetPlanOption.name
                state.masterSetPlanFormDataState.type = masterSetPlanOption.type
                state.masterSetPlanFormDataState.scale = masterSetPlanOption.scale
            }
        },

        updateMasterSetPlanFormState: (
            state: MasterSetPlanState,
            action: PayloadAction<Partial<MasterSetPlanRegionFormFields>>
        ) => {
            state.masterSetPlanFormDataState = {
                ...state.masterSetPlanFormDataState,
                ...action.payload,
            }
        },
        updateSingleMasterSetPlanState: (state: MasterSetPlanState, action: PayloadAction<IMasterSetPlanOption>) => {
            state.masterSetPlanOptions = state.masterSetPlanOptions.map((m) =>
                m.id === action.payload.id ? action.payload : m
            )
        },

        setConflictedMaterialOptionModifications(
            state: MasterSetPlanState,
            action: PayloadAction<IMaterialModificationConflict[]>
        ): void {
            state.materialModificationConflicts = action.payload
        },

        setSavedMaterialModifications(
            state: MasterSetPlanState,
            action: PayloadAction<IMaterialModificationConflict[]>
        ): void {
            state.savedMaterialModifications = action.payload
        },

        resetMaterialModificationConflicts(state: MasterSetPlanState): void {
            state.materialModificationConflicts = []
            state.materialModificationConflictsToSave = []
        },
        updateMaterialModificationConflictToSave(
            state: MasterSetPlanState,
            action: PayloadAction<IMaterialModificationConflict[]>
        ): void {
            const newConflicts = action.payload
            const newConflictIds = new Set(newConflicts.map((conflict) => conflict.id))

            state.materialModificationConflictsToSave = [
                ...state.materialModificationConflictsToSave.filter(
                    (existingConflict) => !newConflictIds.has(existingConflict.id)
                ),
                ...newConflicts,
            ]
        },
        updateConflictResolutionFlag(state: MasterSetPlanState, action: PayloadAction<boolean>) {
            state.isConflictResolutionComplete = action.payload
        },
        updateOverlaidChunkLockedFlag(state: MasterSetPlanState, action: PayloadAction<boolean>) {
            state.isOverlaidChunkLocked = action.payload
        },
    },
})

export const {
    addMasterSetPlanOptionToStore,
    resetMasterSetPlanFormState,
    removeMasterSetPlanOptionById,
    updateMasterSetPlanOptions,
    setActiveMasterSetPlanOption,
    updateMasterSetPlanFormState,
    setMasterSetPlanToCreate,
    updateSingleMasterSetPlanState,
    setConflictedMaterialOptionModifications,
    setSavedMaterialModifications,
    resetMaterialModificationConflicts,
    updateMaterialModificationConflictToSave,
    updateConflictResolutionFlag,
    updateOverlaidChunkLockedFlag,
} = masterSetPlanSlice.actions

export default masterSetPlanSlice

function determineFormNameValue(parentMapping: DocumentMapping | null, formDataType: string | null) {
    if (formDataType) return formDataType

    if (parentMapping && parentMapping.type) return parentMapping.type

    return null
}

export const selectMasterSetPlanToCreate = createSelector(
    [
        (state: RootState) => state.IMUP.masterSetPlan.masterSetPlanToCreate,
        (state: RootState) => state.IMUP.masterSetPlan.masterSetPlanOptions,
        (state: RootState) => state.IMUP.masterSetPlan.activeMasterSetPlanOptionId,
        (state: RootState) => state.IMUP.masterSetPlan.masterSetPlanFormDataState,
        (state: RootState) => selectActiveDocumentMapping(state),
    ],
    (optionsOrBaseHomesToCreate, allOptionsOrBaseHomes, activeOptionId, formData, activeMapping) => {
        const mappingType = determineFormNameValue(activeMapping, formData.type)

        return {
            masterSetPlanToCreate: optionsOrBaseHomesToCreate,
            masterSetPlanFormDataState: {
                name: formData.name,
                type: mappingType,
                scale: formData.scale,
            },
            masterSetPlanOptions: allOptionsOrBaseHomes,
            activeMasterSetPlanOptionId: activeOptionId,
        }
    }
)

export const selectMasterSetPlanOptionsForActivePage = createSelector(
    [
        (state: RootState) => state.IMUP.documents.projectDocumentMappings,
        (state: RootState) => state.IMUP.documents.activeDocumentChunkId,
    ],
    (documentMappings, activeFloorDocId) => {
        const options: IMasterSetPlanOption[] = []

        if (!documentMappings) return options

        const pageMapping = documentMappings.find((mapping) => mapping.document_chunk_id === activeFloorDocId)

        if (
            !pageMapping ||
            (pageMapping && !pageMapping.additional_data.baseHomes && !pageMapping.additional_data.options)
        ) {
            return options
        }

        const combinedMappings = [...pageMapping.additional_data.baseHomes!, ...pageMapping.additional_data.options!]

        combinedMappings.forEach(({ id, coordinates }) => {
            const optionMapping = documentMappings.find((mapping) => mapping.id === id)

            if (optionMapping) {
                options.push({
                    id,
                    type: optionMapping.type,
                    coordinates,
                    name: optionMapping.page_name ?? '',
                    isOption: optionMapping.is_option,
                    document_chunk_id: pageMapping.document_chunk_id,
                    scale: optionMapping.scale_factor,
                })
            }
        })

        return options
    }
)

export const selectMasterSetPlanOptions = createSelector(
    (state: RootState) => state.IMUP.masterSetPlan.masterSetPlanOptions,
    (options) => options
)

export const selectMaterialModificationConflicts = createSelector(
    (state: RootState) => {
        return {
            materialModificationConflicts: state.IMUP.masterSetPlan.materialModificationConflicts,
            materialModificationConflictsToSave: state.IMUP.masterSetPlan.materialModificationConflictsToSave,
        }
    },
    (state) => state
)

export const selectSavedMaterialModifications = createSelector(
    (state: RootState) => state.IMUP.masterSetPlan.savedMaterialModifications,
    (matMods) => matMods
)
